import React, { useEffect, useState } from 'react';
import './login.css';
import OtpInput from 'react18-input-otp'
import apiService from '../../services/apiService';
import CustomToast from '../Toast';
import LoginFooter from "./loginFooter";
import AcceptInvitationComponent from "../layouts/accept_invitation";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import Messages from '../messages';

const OtpVerification = ({ userDetails, retryAcceptProps }) => {
  const [otp, setOtp] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState('');
  const [inviteUser, setInviteUser] = useState(null)
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpTimer, setOtpTimer] = useState(120);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isContinueModalOpen, setContinueModalOpen] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [isContinue, setIsContinue] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(15);
  const [message, setMessage] = useState('')
  const [isRedirected, setIsRedirected] = useState(false)
  const navigate = useNavigate();

  const openContinueModal = () => {
    setContinueModalOpen(true);
  };

  const closeContinueModal = () => {
    setContinueModalOpen(false);
  };

  useEffect(() => {
    setUserInfo(userDetails);
    if(userDetails && userDetails?.type == 'org-user') {
      getOrgInviteUserDetails(userDetails?.uid)
    } else {
      getInviteUserDetails(userDetails?.invite_id)
    }
  }, [userDetails]);

  useEffect(() => {
    let timerId;

    if (otpTimer > 0) {
      timerId = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (otpTimer === 0) {
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showOTP, otpTimer]);

  const getInviteUserDetails = (invite_id) => {
    apiService.get(`get-invite-detail-by-id/${invite_id}`).then((inviteData)=> {
      if(inviteData) {
        setInviteUser(inviteData.data)
      }
    }).catch((err)=> {
      CustomToast.error(err)
    })
  }

  const getOrgInviteUserDetails = (uid) => {
    apiService.get(`get-org-invite-detail-by-id/${uid}`).then((inviteData)=> {
      if(inviteData) {
        setInviteUser(inviteData.data)
      }
    }).catch((err)=> {
      CustomToast.error(err)
    })
  }

  const handleOTPChange = (e) => {
    const otpValue = e.target.value;
    setOtp(otpValue);
  };
  // const redirectTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  // };

  const handleVerifyOTP = async () => {
    try {
      if (otp.length === 6 && /^\d+$/.test(otp)) {
        const obj = {
          uid: userInfo.uid,
          code: otp,
          invite_id: userInfo.invite_id,
        };
        const endpoint = userInfo?.type === 'org-user' ? 'verify-org-user-invite' : 'assign-course-to-org-user';
  
        const result = await apiService.post(endpoint, obj);
  
        if (result && result.status === 'success') {
          const token = result.data;
          const encodedToken = encodeURIComponent(JSON.stringify(token));
  
          CustomToast.success('User created successfully');
  
          const redirectURL =
          process.env.REACT_APP_ENV === 'development'
            ? userInfo?.type === 'org-user'
              ? 'https://fstage-org.inclusional.com/'
              : 'https://fstage-lms.inclusional.com'
            : userInfo?.type === 'org-user'
            ? 'https://fstage-org.inclusional.com/'
            : 'https://fstage-lms.inclusional.com';
          window.location.href = `${redirectURL}?ref=${encodedToken}`;
          setInviteUser(null)
          // setIsRedirected(true)
        } else {
          handleError(result);
        }
      } else {
        setError('Please enter a valid 6-digit OTP.');
        CustomToast.error('Please enter a valid 6-digit OTP.');
      }
    } catch (error) {
      handleError(error);
    }
  };
  
  const handleError = (error) => {
    if (error && error.message && error.message.includes('Failed to assign course: Invalid request or code!')) {
      CustomToast.info('Incorrect OTP');
    } else if (error && error.message && error.message.includes('Course already accepted')) {
      setMessage('Course already accepted please try login')
    }
    else {
      CustomToast.error('Failed to verify OTP. Please try again.');
    }
  };

  const handleResendOTP = async () => {
    try {
        const result = await apiService.post('accept-and-assign-course', retryAcceptProps);
        if (result && result.status === "success") {
          setOtpTimer(120); 
          setShowOTP(true);
          CustomToast.success('OTP send successfully')
        } else {
          CustomToast.error('Failed to verify token');
        }
    } catch (error) {
      CustomToast.error(error);
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 15);
    const remainingSeconds = seconds % 15;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const redirectToLogin = () => {
    window.location.href = process.env.REACT_APP_LOGIN_URL
    // process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
    // setIsModalOpen(false);
  }

  const handleContinue = () => {

    const now = new Date();

    const dateTimeString = now.toLocaleString(); 

    setCurrentDateTime(dateTimeString);

    console.log(dateTimeString);

    setIsContinue(true);

    closeContinueModal();
  };

  return (
    <>
    {console.log("dfssds",inviteUser)}
     {inviteUser ? 
      <div className='login'>
        <div className='login-bottom'>
            <div className='container-fluid p-0'>
              <div className='bg-login'>
                <img src="/images/logo.svg" className='logo'></img>
                {isContinue && (
                  <div className='container'>
                    <div className='row d-flex justify-content-between w-100 h-100 m-auto'>
                      <div className='col-md-6'>
                        <img src='/images/login-bg.png' alt="login" className='w-100 d-none p-5' />
                      </div>
                      <div className='col-md-6'>
                        <div className='login-otp py-2 d-flex flex-column justify-content-center'>
                          <div className='login-details text-center d-flex flex-column justify-content-center align-items-center mb-lg-3 mb-3'>
                            <div className='org-logo'>
                              <img src={inviteUser?.org_image || ''} className='w-100 py-4 mx-auto' />
                            </div>
                            <h3 className='login-title fw-bold font-26 my-lg-3 my-0'>Hi, {inviteUser?.name || ''}</h3>
                            <p className='mb-0 font-18 text-center px-3'>
                              Welcome to Inclusional! Please verify your account by entering the OTP sent to <strong> {inviteUser?.email || ''}</strong>.
                            </p>
                            {/* <p className='mb-0'><b>{inviteUser?.email || 'nidhi.shah@gmail.com'}</b> below to become</p>
        <p className='mb-0'><b>{inviteUser?.designation || 'Learner'}</b> for your organisation</p> */}
                          </div>

                          {/* <div className="otp-input">
        <OtpInput
          value={otp}
          onChange={(otp) => handleOTPChange({ target: { value: otp } })}
          numInputs={6}
          inputStyle={{
            border: 'none',
            borderBottom: '1px solid #ddd',
            width: '35px',
            height: '35px',
            fontSize: '20px',
            color: '#000',
            fontWeight: '400',
            caretColor: 'black',
            margin: '10px',
            outline: 'none',
          }}
        />
        </div> */}

                          <div className="otp-input justify-content-center d-flex align-items-center">
                            <OtpInput
                              value={otp}
                              onChange={(otp) => handleOTPChange({ target: { value: otp } })}
                              numInputs={6}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{
                                border: "1px solid #CCC",
                                borderRadius: "7px",
                                width: "69px",
                                height: "67px",
                                fontSize: "20px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "black",
                                margin: "10px",
                                outline: 'none',
                              }}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                          {/* <p className='resend-otp'>
            <span className='login-resend'>Resend OTP</span> 
            <span className='login-time'>00.15</span>
        </p> */}

                          <p className='resend-otp px-3 d-flex justify-content-center align-items-center mb-lg-0 mb-4'>
                            <span className='login-resend'>
                              <button
                                type="button"
                                className="btn btn-link primary_color text-dark-grey px-2 py-0 text-decoration-none"
                                onClick={handleResendOTP}
                                disabled={otpTimer > 0}
                              >
                                You can resend OTP in
                              </button>
                            </span>
                            <span className='login-time otp-timer'>{formatTime(otpTimer)}</span>
                          </p>

                          <div className='text-center w-100'>
                            <button className='btn btn-md btn-primary otp-btn py-lg-2 py-1 mt-lg-4 mt-0' onClick={handleVerifyOTP}>
                              Confirm
                            </button>
                          </div>

                          {/*error && <p className='login-error mt-2'>{error}</p>*/}
                          <p className='login-note'>By proceeding you agree to our <a href='/term' target='_blank' className='login-term text-decoration-none'>Terms and Conditions</a> and <a href='/privacy' target='_blank' className='login-term text-decoration-none'>Privacy Policy</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div> 
            </div>
        </div>
        
          <Modal
            isOpen={isContinueModalOpen}
            onRequestClose={closeContinueModal}
            contentLabel="Modal"
            className='continue-modal bg-white'
          >
            <div className='d-flex flex-column justify-content-center align-items-center mb-lg-4 mb-3'>
              <h3 className='font-26 mb-lg-4 mb-2 fw-semibold mx-auto'>Welcome to Inclusional!</h3>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start font-16'>
              <p className='mb-lg-4 mb-3 fw-semibold'>Hello {inviteUser?.name || ''},</p>
              <p>We're thrilled to have you join Inclusional. To ensure we provide you with a personalised experience, we kindly request some key information. Your organisation admin has already provided us with essential details like your name, email, designation, department and location. </p>
              <p>While these are mandatory, you have the option to enhance your profile by sharing/editing additional information such as your phone number, gender, age group, and even a photo. We believe that this extra data will help us tailor our content and resources to better suit your needs.  </p>
              <p>At Inclusional, we prioritise your privacy. Your data is stored securely, and our practices align with the highest standards of data protection. If at any point you wish to delete your information from our system, simply reach out to your organisation admin, who will process your request. Rest assured, any deleted data will be removed within 30 days, or as stipulated by relevant laws.  </p>
              <p>Ready to embark on your Inclusional journey? Click "Continue" to explore a wealth of resources tailored just for you! </p>
              <div className='d-flex justify-content-between align-items-center w-100 mt-lg-3 mt-2'>
                <button onClick={handleContinue} className='btn btn-md btn-primary py-2 mx-auto continue-btn'>Continue</button>
              </div>
            </div>
          </Modal> 
        <LoginFooter></LoginFooter>
      </div>
        : null
     }
     {/* {isRedirected && <Messages errorMessage={'Go to login'} />} */}
    </>
  );

}

export default OtpVerification;