import React, { useEffect, useState } from 'react';
import './dashboard.css';
import BarChart from './barChart';
import BarChart2 from './barChart2';
import apiService from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';
import Modal from 'react-modal';

const Course = () => {
    const [courseData, setCourseData] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState()
    const [currentSelectedCourse, setCurrentSelectedCourse] = useState()
    const navigate = useNavigate();

    const openModal = (course_status_data) => {
        setCurrentSelectedCourse(course_status_data)
        setIsModalOpen(true);
    } 
    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleDropdownChange = (e) => {
      setSelectedOption(e.target.value);
      console.log('SelectedOption', selectedOption);
    }

    useEffect(() => { 
        window.scrollTo(0, 0);
        let userInfo = sessionStorage.getItem('userDetail')
        if(userInfo) {
            userInfo = JSON.parse(userInfo)
            setUserData(userInfo)
        }
        setLoader(true);
        fetchData(userInfo) 
    }, [])

    const fetchData = async (userInfo) => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.getv1(`get-license-info?orgId=${userInfo?.org_id}`, token);
            if (result && result.status == "success") {
                setCourseData(result.data);
                const obj = {
                    org_id: result?.data[0].org_id,
                    org_name: result?.data[0].org_name
                }
                localStorage.setItem("orgData", JSON.stringify(obj))
                setLoader(false);
            }
        } catch (error) {
            //   setError('Error fetching user data');
            console.error(error);
            setLoader(false);
           //CustomToast.error('Error Occured While fetching license info')
        }
    };

   const inviteUser=(data,courseData)=>{
    localStorage.setItem("selectedCourse",JSON.stringify([courseData]))
    localStorage.setItem('selectedCourseObj', JSON.stringify(courseData));
    localStorage.setItem("ORGID", data.org_id)
    let orgData = {
        org_id : data.org_id,
        org_name: data.org_name
    }
    localStorage.setItem("orgData", JSON.stringify(orgData))
    const filterCourse = data?.course_status?.map((ele => {
        const obj = {
            org_course_id: ele.org_course_id,
            course_id: ele.course_id,
            course_title: ele.name || ele.course_title
        }
        return obj
    })).filter((obj=> obj != undefined && obj != null))
    localStorage.setItem('inviteCourseList', JSON.stringify(filterCourse));
    navigate("/course_invite")

   }

   const assignedLicenseReq = (course_status_data)=> {
        const bodyData = {
            org_course_id : course_status_data?.org_course_id,
            user_id: userData?.uid,
            org_id: userData?.org_id,
            no_license:"100"
        }

        const token = apiService.getAuthToken()
        apiService.postV1(`license-request`, bodyData, token).then(
            (response) => {
                if(response.status == 'success') {
                    CustomToast.success('Request has been sent to Inclusional.')
                }
            },
            (error) => {
            console.error("Error fetching data", error);
            setLoader(false);
            CustomToast.error('Error while requesting license')
            }
        );
    }

    const sendInvite = async(org_id, org_course_id) => {
        try {
            const updateForm = {
                org_id,
                org_course_id
            }
            const token = apiService.getAuthToken();
            const response = await apiService.post('course-reinvite', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Reminder sent successfully.')
                closeModal()
            //   navigate("/manage_user")
            } else {  
              closeModal()
            //   setError('Failed to update profile');
            //   CustomToast.error('Error while sending invitaion')
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            console.error(error);
            // setLoader(false);
            // CustomToast.error('Error while sending invitaion')
          }
    }

    const sendNotify = async(org_id, course_id) => {
        const updateForm = {
            org_id,
            course_id,
            action: "notify" //push
        }

        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Notify to start course.')
                closeModal()
                // navigate("/manage_user")
            } else {
              closeModal()  
            //   setError('Failed to update profile');
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            // setLoader(false);
            console.error(error);
            // CustomToast.error('Error while notify user')
          }
    }

    const sendPush = async(org_id, course_id) => {
        const  updateForm = {
            org_id,
            course_id,
            action:"push"
        }
        try {
            const token = apiService.getAuthToken();
            const response = await apiService.postV1('notify-user-course', updateForm, token);
            if (response && response.status === 'success') {
                CustomToast.success('Push to complete course.')
                closeModal()
                // navigate("/manage_user")
            } else {
              closeModal()  
            //   setError('Failed to update profile');
            }
            // setLoader(false);
          } catch (error) {
            closeModal()
            // setError('An error occurred while updating profile');
            console.error(error);
            // CustomToast.error('Error while notify user')
          }
    }

    return (
        <>
            {loader ? <Loader />
            : <div className='course mt-0'>
            <h3 className='fw-bold font-26 mb-4'>Courses</h3>
            {courseData && courseData?.map((data, index) => {
                return <div>
                  {data&&data?.course_status?.map((course_status_data, index) => {
                    return<div className="card mb-5">
                    <div className="card-body">
                        <h3 className='primary_color text-capitalize font-26 fw-bold mb-3'>{course_status_data?.course_title}</h3>
                        <p className='mb-4'>
                            <span className="text-dark-gray text-capitalize me-4"><img src="/images/time.svg" className='me-2' />{course_status_data?.duration}</span>
                            <span className="text-dark-gray text-capitalize"><img src="/images/modules.png" className='me-2' />{course_status_data?.total_modules} modules</span>
                        </p>
                       {console.log("course_status_data==", course_status_data)}
                        <div className='row'>
                            <div className='col-lg-3 col-6'>
                                <a href={`/stats/${course_status_data?.course_id}/${course_status_data?.course_title}/${course_status_data?.org_course_id}`} className='btn btn-md btn-secondary text-decoration-none w-100'>View Stats</a>

                            </div>
                            <div className='col-lg-3 col-6'>
                                <button className='btn btn-md btn-primary w-100' onClick={()=>inviteUser(data,course_status_data)}>Invite Users</button>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='stats'>
                                <BarChart course_status={course_status_data} />
                            </div>
                        </div>

                    </div>

                   {/*
                    <div className='card-footer custom-bg p-2 text-center'>
                        <p className='mb-0 text-white'>Course Expires on 30 Oct’2023</p>
                    </div> */}
                     <div className='card-footer custom-bg p-1 text-center'>
                      {/*  <button className='btn btn-link mb-0 fw-bold text-decoration-none text-white' onClick={assignedLicenseReq.bind(null, course_status_data)}>Add New Licences</button> */}
                       <button className='btn btn-link text-white text-decoration-none fw-bold' onClick={()=> openModal(course_status_data)}>Send Reminders</button>
                    </div>
                </div>}  )}
                </div>
            })}

          <div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="bg-white" >

            <button type="button" className="close text-white" onClick={closeModal}> <svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
            <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
        </svg></button>

            <div className='p-3 border-0 mt-4 text-center'> 

            <h2 className='font-20 mb-5'>Send Reminder Notifications for course: <span className='text-capitalize fw-bold'>{currentSelectedCourse?.course_title}</span></h2>

                    <div className='w-75 mb-3 m-auto'> <select className='form-select position-relative w-100 h-40 fw-bold' value={selectedOption} onChange={handleDropdownChange}>
                        <option value="select">Select</option>
                        <option value="invited">Remind to Accept Invitation </option>
                        <option value="notstarted">Remind to Start  Course</option>
                        <option value="inprogress">Remind to Complete The Course</option>
                    </select>
                    </div>
            
                {selectedOption === 'invited' && (
                    <>
                        <h3 className='font-18 my-4'><span className='fw-bold'>{Number(currentSelectedCourse?.total_used) - Number(currentSelectedCourse?.accepted)}</span> users will be notified.</h3>
                        <button className='btn btn-md btn-primary'onClick={() => sendInvite(localStorage.getItem('ORGID'), currentSelectedCourse?.org_course_id)}>Notify</button>
                    </>
                )}

                {selectedOption === 'notstarted' && (
                    <>
                        <h3 className='font-18 my-4'><span className='fw-bold'>{currentSelectedCourse?.notstarted}</span> users will be notified.</h3>
                        <button className='btn btn-md btn-primary' onClick={() => sendNotify(localStorage.getItem('ORGID'), currentSelectedCourse?.course_id)}>Notify</button>
                    </>
                )}

                {selectedOption === 'inprogress' && (
                    <>
                        <h3 className='font-18 my-4'><span className='fw-bold'>{currentSelectedCourse?.inprogress}</span> users will be notified.</h3>
                        <button className='btn btn-md btn-primary' onClick={() => sendPush(localStorage.getItem('ORGID'), currentSelectedCourse?.course_id)}>Notify</button>
                    </>
                )}
                </div>
            </Modal>
    
                    </div> 

        </div>}
        </>
    )

}

export default Course;